import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { HeroContainer, Section } from 'components/Container';
import { Accordion } from 'components/Accordion';

import { useFAQ } from 'page-data/faq';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const FAQ: FC<LocalizedPageProps> = () => {
  const details = useFAQ();

  return (
    <Layout>
      <Seo />
      <HeroContainer>
        <Section>
          <h1>Frequently Asked Questions</h1>
        </Section>
      </HeroContainer>

      <Accordion
        title="Find answers to the questions we’re frequently asked about"
        details={details}
        background="cream"
      />
    </Layout>
  );
};

export default FAQ;
