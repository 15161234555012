import { useMemo } from 'react';

import { BlogLink, GatsbyLink, HelpLink, SignupLink } from 'components/Links';
import { List } from 'components/List';
import { capitalize } from 'helpers/capitalize';
import { Container, Section, SectionVideo } from 'components/Container';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

const useWhatCanITrackItems = () => {
  const {
    translations: { stock, supportedMarkets, supportedCurrencies, supportedCryptoCurrencies },
  } = useContentfulLocale();

  return useMemo(
    () => [
      {
        id: 'stocks-and-etfs',
        content: (
          <>
            {capitalize(`${stock}s`)} and ETFs from over{' '}
            <GatsbyLink to="faq/#question-which-stock-exchanges-does-sharesight-support">
              {supportedMarkets} stock exchanges
            </GatsbyLink>{' '}
            (including dividends and distributions)
          </>
        ),
      },
      { id: 'listed-bonds', content: 'Listed bonds on the ASX and NZX' },
      {
        id: 'mutual-managed-funds',
        content: 'Australian, New Zealand, Canadian, UK, and US mutual/managed funds',
      },
      {
        id: 'supported-currencies',
        content: (
          <BlogLink to="track-investments-in-fx-currencies-with-sharesight">
            Over {supportedCurrencies} currencies
          </BlogLink>
        ),
      },
      {
        id: 'supported-cryptocurrencies',
        content: (
          <BlogLink to="track-bitcoin-and-other-cryptocurrencies-with-sharesight">
            {supportedCryptoCurrencies} major cryptocurrencies
          </BlogLink>
        ),
      },
      {
        id: 'gold-and-other-precious-metals',
        content: (
          <>
            {' '}
            <BlogLink to="why-invest-in-commodities-like-gold">
              Gold and other precious metals
            </BlogLink>{' '}
            (as custom investments)
          </>
        ),
      },
      {
        id: 'unlisted-investments',
        content: (
          <>
            Unlisted investments such as fixed interest,{' '}
            <BlogLink to="what-is-peer-to-peer-lending">P2P lending</BlogLink>,{' '}
            <BlogLink to="how-to-track-an-investment-property-in-sharesight">property</BlogLink>,{' '}
            <BlogLink to="what-is-a-capital-call-transaction">private equity</BlogLink> and options
            (as custom investments)
          </>
        ),
      },
    ],
    [stock, supportedCryptoCurrencies, supportedCurrencies, supportedMarkets]
  );
};

export const useFAQ = () => {
  const {
    translations: { stock, freeUsers },
  } = useContentfulLocale();

  const whatCanITrackItems = useWhatCanITrackItems();

  return useMemo(
    () => [
      {
        question: 'What is Sharesight?',
        answer: (
          <p>
            Designed for self-directed investors, Sharesight is a comprehensive online portfolio
            tracking tool that automatically tracks the performance of your investments –
            eliminating the need for Excel spreadsheets. Unlike the data provided by most brokers,
            Sharesight considers the impact of capital gains, dividends, brokerage fees and currency
            fluctuations when{' '}
            <BlogLink to="how-sharesight-calculates-your-investment-performance">
              calculating returns
            </BlogLink>
            , giving investors the complete picture of their performance and allowing them to make
            better investment decisions.
          </p>
        ),
      },
      {
        question: 'Why choose Sharesight?',
        answer: (
          <p>
            With the ability to track investments across different asset classes, markets,
            currencies and brokers, Sharesight is the ultimate solution to track all of your
            investments in one place. Unlike most brokers, Sharesight gives investors the true
            picture of their performance by providing a total annualised return that takes into
            account the impact of capital gains, dividends, brokerage fees and currency
            fluctuations. Plus, Sharesight offers a suite of advanced performance and tax reports
            designed for the needs of self-directed investors, including{' '}
            <HelpLink to="/performance_report/">Performance</HelpLink>,{' '}
            <HelpLink to="/diversity_report/">Portfolio Diversity</HelpLink>,{' '}
            <BlogLink to="explore-your-portfolio-performance-with-the-contribution-analysis-report">
              Contribution Analysis
            </BlogLink>
            ,{' '}
            <BlogLink to="value-your-investments-in-any-currency-with-sharesight">
              Multi-Currency Valuation
            </BlogLink>{' '}
            and <HelpLink to="/future_income/">Future Income</HelpLink> (upcoming dividends). And
            importantly, Sharesight automates portfolio tracking, meaning investors spend less time
            on tedious portfolio admin (especially at tax time), leaving them with more time to make
            investment decisions.
          </p>
        ),
      },
      {
        question: 'Who is Sharesight for?',
        answer: (
          <p>
            Sharesight is a portfolio tracking and reporting tool for anyone who owns investments,
            has a desire to understand how those investments are actually performing, or needs to
            produce tax reports. We have plans for individual investors, and{' '}
            <GatsbyLink to="become-a-partner">finance companies</GatsbyLink>.
          </p>
        ),
      },
      {
        question: 'How does Sharesight work?',
        answer: (
          <>
            <p>
              Sharesight automatically calculates the performance of your overall portfolio and
              individual {stock}s over any time period. Capital gains, dividends, and currency
              movements are all shown in dollars and annualised percentages.
            </p>
            <p>
              We then provide you with a number of different tools that allow you to view your
              portfolio’s true performance, but in different ways. For example, you can use one of
              our graphing features such as our growth graph, value graph or our sophisticated
              performance index chart.
            </p>
            <p>
              In addition, you can{' '}
              <BlogLink to="benchmark-your-portfolio-against-any-stock-etf-or-fund">
                benchmark
              </BlogLink>{' '}
              your performance against an extensive number of common funds or markets.
            </p>
            <p>
              We also provide different options for grouping your holdings, including grouping them
              by market, country, industry classification, investment type, or you could use our
              custom groupings feature and group your holdings based on an asset allocation model of
              your choosing.
            </p>
            <p>
              Sharesight also provides a comprehensive suite of reports. All users, including those
              on our Free plan, have access to our Performance Report, Dividend Tax Report, Sold
              Securities Report and Historical Cost Report. Additional reports are available to
              users on our <GatsbyLink to="pricing">paid plans</GatsbyLink> that will offer you
              further insight into your {stock} portfolio’s true performance.
            </p>
            <p>Watch our explainer video to see it in action:</p>
            <br />
            <Container padding={1} background="cream">
              <SectionVideo videoUrl="https://www.youtube.com/watch?v=DD8FmH2Hr74" />
              <Section />
            </Container>
          </>
        ),
      },
      {
        question: 'How to use Sharesight?',
        answer: (
          <p>
            To get started, first <SignupLink>sign up</SignupLink> for a Sharesight account. Once
            you have an account, you can start adding trades to your portfolio by importing your
            trading history{' '}
            <BlogLink to="automatically-import-trades-to-your-sharesight-portfolio-using-email">
              directly from your broker
            </BlogLink>{' '}
            or through a{' '}
            <BlogLink to="import-trades-with-the-sharesight-file-importer">
              spreadsheet file
            </BlogLink>
            . Alternatively, you may choose to manually add trades to your portfolio. Once you have
            populated your portfolio with trades, capital gains, dividends and currency fluctuations
            will be tracked automatically, requiring minimal admin on your part.
          </p>
        ),
      },
      {
        question: 'What can I track in Sharesight?',
        answer: (
          <>
            <p>You can use Sharesight to track the following investments:</p>
            <List items={whatCanITrackItems} />
            <br />
            <p>Please note that Sharesight does not allow tracking of short selling or CFDs.</p>
          </>
        ),
      },
      {
        question: 'Which stock exchanges does Sharesight support?',
        answer: (
          <p>
            Sharesight supports over 50 stock exchanges and managed funds, meaning that you can
            automatically track price, performance and dividends from 240,000+ global stocks,
            crypto, ETFs and funds. Find out which stock exchanges we support{' '}
            <GatsbyLink to="supported-stock-exchanges-managed-funds-mutual-funds">here</GatsbyLink>.
          </p>
        ),
      },
      {
        question: 'Is Sharesight worth it?',
        answer: (
          <p>
            Trusted by over {freeUsers} users worldwide, Sharesight helps investors save time and
            money on their investments by eliminating tedious portfolio admin, allowing them to
            easily share their portfolio with an accountant or financial adviser, and providing
            advanced tax and performance reporting designed for self-directed investors. It’s free
            to sign up for Sharesight, and will remain free for investors tracking 10 holdings or
            less. So what are you waiting for? <SignupLink>Sign up</SignupLink> for Sharesight today
            and see the value for yourself.
          </p>
        ),
      },
      {
        question: 'How much does Sharesight cost?',
        answer: (
          <p>
            You may track up to 10 holdings for free. Once you have more than 10 investments, or
            would like access to our advanced features and full reporting suite, you may choose to
            upgrade to a paid plan (with a free trial). See our{' '}
            <GatsbyLink to="pricing">pricing plans</GatsbyLink> for more information.
          </p>
        ),
      },
      {
        question: 'Is Sharesight tax deductible?',
        answer: <p>Sharesight might be tax-deductible, ask your accountant for details.</p>,
      },
      {
        question: 'Who owns Sharesight?',
        answer: (
          <p>
            Sharesight was <GatsbyLink to="about-us">started by a father and son</GatsbyLink> in New
            Zealand in 2007. Today, we’re owned by customers turned investors and are proud of our
            fierce independence. In fact, our last capital raises were funded by{' '}
            <BlogLink to="sharesight-raises-2-million-dollars">investors like you</BlogLink>.
          </p>
        ),
      },
      {
        question: 'Is Sharesight safe?',
        answer: (
          <p>
            Sharesight is committed to{' '}
            <HelpLink to="/how-sharesight-protects-your-data/">keeping your data safe</HelpLink>.
            All data sent between you and our servers is encrypted using modern, industry standard
            Transport Layer Security (TLS). Sharesight’s servers are located in enterprise-grade
            data centres that employ numerous physical security measures including 24/7/365
            monitoring and surveillance, on-site security staff and regular ongoing security audits.
            Sharesight is also independently audited on a regular basis to ensure that the IT
            security of our software meets best practice guidelines. For an additional layer of
            security, users are encouraged to enable{' '}
            <HelpLink to="/two-factor-authentication/">2-factor authentication</HelpLink>.
          </p>
        ),
      },
      {
        question: 'Doesn’t my broker / financial adviser offer me all of these features already?',
        answer: (
          <>
            <p>
              No. Your broker’s revenue stream is dependant on trades. Every time you buy and sell,
              your broker takes a cut of the transaction. As such, your broker has an incentive to
              see you trade more regularly. We don’t. Sharesight is either free or has a low fixed
              monthly cost (See our <GatsbyLink to="/pricing/">pricing plans</GatsbyLink> for more
              information).
            </p>
            <p>
              Our mission is to offer you the best visibility we can into your portfolio’s true
              performance. We have no hidden agenda, or incentives that might cause us to stray from
              this single goal.
            </p>{' '}
            <p>
              As such we believe our performance calculations, automatic dividend importing and
              other complex corporate action processing, graphing features, performance and tax
              reports are second to none. We’re confident you won’t find another tool that gives you
              such a comprehensive understanding of your assets.
            </p>
            <p>
              <strong>
                Ultimately, the best way to understand what Sharesight is all about is to{' '}
                <SignupLink>sign up</SignupLink> and try it for yourself. We’re confident that
                you’ll agree that it’s the best portfolio tracker available today.
              </strong>
            </p>
          </>
        ),
      },
    ],
    [freeUsers, stock, whatCanITrackItems]
  );
};
